import { configureStore } from "@reduxjs/toolkit";
import { boTheBotApi } from "./baseApi";

export const store = configureStore({
  reducer: {
    [boTheBotApi.reducerPath]: boTheBotApi.reducer,
  },
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware().concat(boTheBotApi.middleware),
});
