import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";
import {
  BaseQueryFn,
  FetchArgs,
  FetchBaseQueryError,
} from "@reduxjs/toolkit/query/react";

const baseQuery: BaseQueryFn<
  string | FetchArgs,
  unknown,
  FetchBaseQueryError
> = async (args, api, extraOptions) => {
  const baseResult = await fetchBaseQuery({
    baseUrl: `${process.env.REACT_APP_PLUGIN_API_URL}`,
  })(args, api, extraOptions);

  if (baseResult.error && baseResult.error.status === 403) {
  }
  return {
    ...baseResult,
  };
};

export const boTheBotApi = createApi({
  reducerPath: "boTheBotApi",
  baseQuery,
  endpoints: () => ({}),
  tagTypes: ["User", "Order"],
});
