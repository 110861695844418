import { FC } from "react";
import "../styles/navbar.css";
import appImages from "../constants/images";

export const Navbar: FC = () => {
  return (
    <div className="main-container">
      <img className="boTag-logo" src={appImages.BO_TAG_LOGO} />
    </div>
  );
};
