import { useState } from "react";
import { Navbar } from "../components/Navbar";
import appImages from "../constants/images";
import "../styles/home.css";
import { useFetchDemoGtinQuery } from "../store/product/ProductApi";

const Home = () => {
  const [showIframe, setShowIframe] = useState(false);
  const handleMessage = (event: MessageEvent) => {
    if (event.data === "hideButton") {
      setShowIframe(false);
    }
  };

  const { isLoading, data, isSuccess } = useFetchDemoGtinQuery({});
  const barcode = data?.result?.demoPluginGTIN;
  const braandLabelId = data?.result?.demoPluginLabelBrand;
  window.addEventListener("message", handleMessage);
  console.log(`${process.env.REACT_APP_PLUGIN_SRC}${barcode}/${braandLabelId}`);
  console.log(process.env.REACT_APP_PLUGIN_SRC, "url");
  console.log(barcode, "barcode");
  console.log(braandLabelId, "brandlabel");

  return (
    <>
      <div className="main">
        <Navbar />
        <div className="content">
          <img className="full-image" src={appImages.ITEM_IMAGE} />
          <div className="description">
            <h2 className="heading-2">
              Blazer med fiskebensmønster i regular-fit
              <br />
            </h2>
            <div className="second-headings">
              <h2 className="heading-27">- Relaxed fit</h2>
              <h2 className="heading-27">- Dobbelspent</h2>
              <h2 className="heading-27">- Fullt fôret</h2>
              <h2 className="heading-27">- Hoftelengde</h2>
            </div>
            <div style={{ fontSize: "14px" }}>
              Color:&nbsp;<strong>Beige </strong>/ Black / Purple
            </div>
            <div>
              <div style={{ fontSize: "14px" }}>
                Size: XS / S /&nbsp;<strong>M </strong>/&nbsp;L /&nbsp;XL
              </div>
            </div>
            <div style={{ fontSize: "14px" }}>Price: 3.900 kr</div>
            {isSuccess ? (
              <a
                data-w-id="83b030d9-c2eb-33cd-7f3c-1335e7fe8ad4"
                style={{ backgroundColor: "rgb(44, 44, 44)" }}
                href="#"
                className="button"
                onClick={() => {
                  setShowIframe(true);
                }}
              >
                <p
                  style={{ color: "rgb(255, 255, 255)" }}
                  className="paragraph-16px text-color-white"
                >
                  See stores with BOtag
                </p>
              </a>
            ) : null}
          </div>
        </div>
      </div>
      {showIframe && data && (
        <iframe
          title="BoTag Plugin"
          src={`${process.env.REACT_APP_PLUGIN_SRC}${barcode}/${braandLabelId}`}
          style={{
            height: "100vh",
            position: "fixed",
            top: "0%",
            right: "0%",
            width: "100%",
          }}
        />
      )}
    </>
  );
};

export default Home;
