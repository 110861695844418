import { boTheBotApi } from "../baseApi";
import { FETCH_DEMO_GTIN } from "../../api/Endpoints";
export const OrderApiEndpoints = boTheBotApi.injectEndpoints({
  endpoints: (builder) => ({
    fetchDemoGtin: builder.query({
      query: () => ({
        url: `${FETCH_DEMO_GTIN}`,
      }),
      providesTags: ["Order"],
    }),
  }),
});

export const { useFetchDemoGtinQuery } = OrderApiEndpoints;
